import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`23rd April 2019`}</p>


    <h2 {...{
      "id": "new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#new-features",
        "aria-label": "new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can now access the detailed timing information for the connection attempts made by Armeria via `}<inlineCode parentName="p">{`RequestLog`}</inlineCode>{` API, including DNS lookup and socket connect time. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1692"
          }}>{`#1692`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpClient client = new HttpClientBuilder("https://example.com/")
    .decorator((delegate, ctx, req) -> {
        ctx.log().addListener(log -> {
            ClientConnectionTimings timings = ClientConnectionTimings.get(log);
            System.err.printf("Total time taken: %d ns%n",
                              timings.connectionAcquisitionDurationNanos());
            System.err.printf("DNS lookup: %d ns%n",
                              timings.dnsResolutionDurationNanos());
            System.err.printf("Socket connect:: %d ns%n",
                              timings.socketConnectDurationNanos());
        }, RequestLogAvailability.REQUEST_START);
        return delegate.execute(ctx, req);
    })
    .build();
client.get("/");
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now customize server-side `}<inlineCode parentName="p">{`SslContext`}</inlineCode>{` more conveniently. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1717"
          }}>{`#1717`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerBuilder sb = new ServerBuilder();
sb.tls(certChainFile, keyFile, tlsContextBuilder -> {
    // Use JDK SSLEngine instead of OpenSSL.
    tlsContextBuilder.sslProvider(SslProvider.JDK);
});
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Server.stop()`}</inlineCode>{` is now able to stop its `}<inlineCode parentName="p">{`blockingTaskExecutor`}</inlineCode>{`. You have to specify whether to stop the `}<inlineCode parentName="p">{`blockingTaskExecutor`}</inlineCode>{` you specified or not when calling `}<inlineCode parentName="p">{`ServerBuilder.blockingTaskExecutor()`}</inlineCode>{` from this release. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1685"
          }}>{`#1685`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1707"
          }}>{`#1707`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerBuilder sb = new ServerBuilder();
// The thread pool will be terminated when the server stops.
sb.blockingTaskExecutor(Executors.newFixedThreadPool(100), true);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The usability of `}<inlineCode parentName="p">{`RedirectService`}</inlineCode>{` has been improved with some API changes. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1725"
          }}>{`#1725`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1726"
          }}>{`#1726`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`RedirectService`}</inlineCode>{` preserves the query string by default. The query string of the old location was dropped previously.`}</li>
          <li parentName="ul">{`Added `}<inlineCode parentName="li">{`preserveQueryString`}</inlineCode>{` constructor parameter. Specifying `}<inlineCode parentName="li">{`false`}</inlineCode>{` will let you go back to the previous behavior.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The usability of `}<inlineCode parentName="p">{`SettableHealthChecker`}</inlineCode>{` has been improved with some API changes. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1714"
          }}>{`#1714`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`SettableHealthChecker`}</inlineCode>{`'s initial healthiness is now `}<inlineCode parentName="li">{`true`}</inlineCode>{`. It was `}<inlineCode parentName="li">{`false`}</inlineCode>{` previously.`}</li>
          <li parentName="ul">{`You can now specify the initial healthiness when instantiating `}<inlineCode parentName="li">{`SettableHealthChecker`}</inlineCode>{`.`}
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`SettableHealthChecker myHealthChecker = new SettableHealthChecker(false);
ServerBuilder sb = new ServerBuilder();
sb.service("/monitor/health", new HttpHealthCheckService(myHealthChecker));
`}</code></pre>
          </li>
          <li parentName="ul"><inlineCode parentName="li">{`SettableHealthChecker.setHealthy(boolean)`}</inlineCode>{` is now chainable. `}<a parentName="li" {...{
              "href": "https://github.com/line/armeria/issues/1714"
            }}>{`#1714`}</a>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`// Same with: new SettableHealthChecker(false)
SettableHealthChecker myHealthChecker = new SettableHealthChecker().setHealthy(false);
`}</code></pre>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify different path mappings for different CORS policies. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1698"
          }}>{`#1698`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1699"
          }}>{`#1699`}</a></p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`Using a builder pattern:`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`ServerBuilder sb = new ServerBuilder();
// Add two services under /foo and /bar.
sb.service("prefix:/foo", fooService);
sb.service("prefix:/bar", barService);

// Enable CORS for:
// - GET and POST requests for all resources under http://example.com/foo/
// - GET request for all resources under http://example.com/bar/
sb.decorator(CorsServiceBuilder.forOrigins("http://example.com")
                               .pathMapping("prefix:/foo")
                               .allowCredentials()
                               .allowRequestMethods(HttpMethod.GET, HttpMethod.POST)
                               .andForOrigins("http://example.com")
                               .pathMapping("prefix:/bar")
                               .allowCredentials()
                               .allowRequestMethods(HttpMethod.GET)
                               .and()
                               .newDecorator());
`}</code></pre>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Using annotations:`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`sb.annotatedService(
    @CorsDecorator(
        origins = "http://example.com",
        pathPatterns = "prefix:/foo",
        credentialsAllowed = true,
        allowedRequestMethods = { HttpMethod.GET, HttpMethod.POST })
    @CorsDecorator(
        origins = "http://example.com",
        pathPatterns = "prefix:/bar",
        credentialsAllowed = true,
        allowedRequestMethods = { HttpMethod.GET })
    new Object() {
        ...
    });
`}</code></pre>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now build a gRPC service without depending on upstream gRPC API. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1720"
          }}>{`#1720`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1727"
          }}>{`#1727`}</a></p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`Added `}<inlineCode parentName="p">{`armeria-grpc-protocol`}</inlineCode>{` which provides a core functionality required for building a gRPC service, which does not depend on `}<inlineCode parentName="p">{`io.grpc:grpc-java`}</inlineCode>{` but only on `}<inlineCode parentName="p">{`com.google.protobuf:protobuf-java`}</inlineCode>{`.`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Added `}<inlineCode parentName="p">{`AbstractUnaryGrpcService`}</inlineCode>{` which allows you to implement a unary gRPC easily without depending on `}<inlineCode parentName="p">{`io.grpc:grpc-java`}</inlineCode>{`.`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`class MyUnaryGrpcService extends AbstractUnaryGrpcService {
    @Override
    protected CompletableFuture<byte[]> handleMessage(byte[] message) {
        final MyGrpcRequest req;
        try {
            req = MyGrpcRequest.parseFrom(message);
        } catch (InvalidProtocolBufferException e) {
            throw new UncheckedIOException(e);
        }

        MyGrpcResponse res = MyGrpcResponse.newBuilder()...build();
        return UnmodifiableFuture.completedFuture(res.toByteArray());
    }
}
`}</code></pre>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Spring Boot integration now has content encoding options. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1716"
          }}>{`#1716`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-yaml"
          }}>{`# Your application.yml:
armeria:
  ports:
    - port: 8080
      protocols: HTTP
  compression:
    enabled: true
    mime-types: text/*, application/json
    excluded-user-agents: some-user-agent, another-user-agent
    minResponseSize: 1KB
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#bug-fixes",
        "aria-label": "bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`The default path pattern now handles a trailing slash properly. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1730"
        }}>{`#1730`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1735"
        }}>{`#1735`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`TwoElementFixedStreamMessage`}</inlineCode>{` no longer throws an `}<inlineCode parentName="li">{`IllegalReferenceCountException`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1687"
        }}>{`#1687`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1695"
        }}>{`#1695`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`DnsEndpointGroup`}</inlineCode>{` does not ignore the `}<inlineCode parentName="li">{`search`}</inlineCode>{` directive in `}<inlineCode parentName="li">{`/etc/resolv.conf`}</inlineCode>{` anymore. This fix should be useful for users in Kubernetes environment. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1694"
        }}>{`#1694`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1697"
        }}>{`#1697`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`DocService`}</inlineCode>{` no longer raises a `}<inlineCode parentName="li">{`NullPointerException`}</inlineCode>{` for a certain gRPC service metadata. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1705"
        }}>{`#1705`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1715"
        }}>{`#1715`}</a></li>
      <li parentName="ul">{`An unframed gRPC service no longer becomes unresponsive when sending a response without content `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1723"
        }}>{`#1723`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`THttpService`}</inlineCode>{` no longer fails to load the service metadata when `}<inlineCode parentName="li">{`.thrift`}</inlineCode>{` file was compiled with the `}<inlineCode parentName="li">{`private-members`}</inlineCode>{` option. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1728"
        }}>{`#1728`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1729"
        }}>{`#1729`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`THttpService`}</inlineCode>{` no longer fails to load the service metadata when `}<inlineCode parentName="li">{`.thrift`}</inlineCode>{` file defines a service that extends other service. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1734"
        }}>{`#1734`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`HttpTracingClient`}</inlineCode>{` and `}<inlineCode parentName="li">{`HttpTracingService`}</inlineCode>{` do not fill service name automatically with host names or IP addresses anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1706"
        }}>{`#1706`}</a></li>
      <li parentName="ul">{`Spring integration module now respects the `}<inlineCode parentName="li">{`com.linecorp.armeria.useOpenSsl`}</inlineCode>{` flag properly. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1713"
        }}>{`#1713`}</a></li>
    </ul>
    <h2 {...{
      "id": "deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#deprecations",
        "aria-label": "deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Deprecations`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`ServerBuilder.blockingTaskExecutor(Executor)`}</inlineCode>{` has been deprecated. Use `}<inlineCode parentName="li">{`ServerBuilder.blockingTaskExecutor(Executor, boolean)`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1685"
        }}>{`#1685`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1707"
        }}>{`#1707`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`TextFormatter.epoch()`}</inlineCode>{` and `}<inlineCode parentName="li">{`appendEpoch()`}</inlineCode>{` have been deprecated. Use `}<inlineCode parentName="li">{`TextFormatter.epochMillis()`}</inlineCode>{` and `}<inlineCode parentName="li">{`appendEpochMillis()`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1692"
        }}>{`#1692`}</a></li>
    </ul>
    <h2 {...{
      "id": "breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#breaking-changes",
        "aria-label": "breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Breaking changes`}</h2>
    <ul>
      <li parentName="ul">{`The initial healthiness of `}<inlineCode parentName="li">{`SettableHealthChecker`}</inlineCode>{` is now `}<inlineCode parentName="li">{`true`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1714"
        }}>{`#1714`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`RedirectService`}</inlineCode>{` preserves the query string by default. The query string of the old location was dropped previously. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1725"
        }}>{`#1725`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1726"
        }}>{`#1726`}</a></li>
    </ul>
    <h2 {...{
      "id": "dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#dependencies",
        "aria-label": "dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`gRPC 1.19.0 -> 1.20.0`}
        <ul parentName="li">
          <li parentName="ul">{`Protobuf 3.6.1 -> 3.7.1`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Jetty 9.4.15 -> 9.4.17`}</li>
      <li parentName="ul">{`Micrometer 1.1.3 -> 1.1.4`}</li>
      <li parentName="ul">{`Spring Boot 2.1.3 -> 2.1.4, 1.5.19 -> 1.5.20`}</li>
      <li parentName="ul">{`Tomcat 9.0.17 -> 9.0.19, 8.5.39 -> 8.5.40`}</li>
      <li parentName="ul">{`ZooKeeper 3.4.13 -> 3.4.14`}</li>
    </ul>
    <h2 {...{
      "id": "thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#thank-you",
        "aria-label": "thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Thank you`}</h2>
    <ThankYou usernames={['anuraaga', 'codefromthecrypt', 'dawnbreaks', 'edgao', 'geminiKim', 'gquintana', 'huydx', 'hyangtack', 'minwoox', 'syleeeee', 'tacigar', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      